<template>
<div class="home">
    <a-spin class="load" v-show="loading" />
    <div class="messDetail">
        <HeadTop />
        <span style="margin-left:10px">></span>
        <span style="margin-left:10px">{{title}}</span>
    </div>
    <div class="content">
        <div class="contLeft">
            <div class="book-textreadguide">
                <!-- <a-spin class="loading" v-show="loading" /> -->
                    <div class="book-textreadguide-content">
                        <div class="textreadguide-title">{{detailMess.title}}</div>
                        <div class="textreadguide-date">{{format.format(detailMess.createDate)}}</div>
                        <div class="textreadguide-content">
                        {{detailMess.content}}
                        </div>
                    </div>
                    
            </div>
            <div class="dianzan" @click="praise()">
                    <img v-if="isPraise" class="dianZanImg" src="../../assets/img/dianzan2x.png" alt="">
                    <img v-if="!isPraise" class="dianZanImg" src="../../assets/img/dianzansure.png" alt="">
                    <span class="dianZanNum">{{detailMess.praiseNum}}</span>
            </div>

        </div>
        <div class="contRight">
            <div class="title">
            <img width="20px" height="20px" src="../../assets/img/54.png" alt="">
            <span class="good_product">相关推荐</span>
            </div>
            <ul class="pro_list">
            <li @click="lookDetail(item)" class="listDetail" v-for="(item,index) in recommendList" :key="index">
                {{item.title}}
            </li>
            </ul>
        </div>
    </div>
    <div class="replay">
        <div class="answerBgcs">
            <a-textarea v-model="replayValue" :auto-size="{ minRows: 6, maxRows: 10 }" />
            <div style="text-align:right;margin-top:20px;">
                <a-button @click="savePingLun" type="danger">发布</a-button>
            </div>
        </div>
        <div class="list">
            <div class="num">
                <span class="text">全部评论</span>
                <span class="num_num">{{detailMess.commentNum}}</span>
            </div>
            <ul class="listUl">
                <li class="listLi" v-for="(item,index) in pingLunList" :key="index">
                    <div class="imgUser">
                        <img v-if="item.userPhoto!=null" class="userImg" :src="$resBasePath+'/'+item.userPhoto" alt="" />
                        <img v-else class="userImg" src="../../assets/img/03.png" alt="" />
                    </div>
                    <div class="userDetail">
                        <div class="title">{{item.userName}}</div>
                        <div class="content">{{item.content}}</div>
                        <div class="date">{{format.format(item.createDate)}}</div>
                    </div>
                </li>
            </ul>
            <div class="lookMore" @click="getMoreNum()">查看更多</div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import HeadTop from '@/components/HeadTop.vue'
import format from '../../utils/format.js'
export default {
  name: 'policy',
  data (){
      return {
        format,
        loading: false,
        replayValue: '',
        id: '1',
        detailMess:{},
        recommendList:[],
        title: '',
        pingLunList: [], //评论列表
        pageSize: 5,
        pageNum: 1,
        isPraise: true, //是否点赞
        disabled: false
      }
  },
  watch:{
      id:function(){
          this.pageSize = 5
          this.pageNum = 1
          this.pingLunList = []
          this.getCommentPage()
      }
  },
  mounted(){
    if(this.$route){
        const policy = JSON.parse(sessionStorage.getItem('policyMess'))
        this.id = policy.id
        this.title = policy.title
    }
    this.getDetail()
    this.getCommentPage()
  },
  methods:{
        getDetail (){
        this.loading = true
        let _this = this
        const param = {
            id: _this.id
        }
        axios({
          url: _this.$baseUrl + 'official/examHall/getPolicyDetail',
          method: 'POST',
          data: param
        }).then(res => {
          if(res && res.data &&res.data.resultCode == 0){
            _this.detailMess = res.data.resultData
            _this.recommendList = res.data.resultData.recommendList
            _this.loading = false
          }
        })
      },
      getMoreNum(){
          this.pageNum++
          this.getCommentPage()
      },
      getCommentPage(){ // 获取文章评论
        const param = {
            rootId: this.id,
            businessType: '1',
            pageNum: this.pageNum,
            pageSize: this.pageSize
        }
        let _this = this
        axios({
            url: _this.$baseUrl + 'official/comment/getCommentPage',
            method: 'POST',
            data: param
        }).then(res => {
            if(res&&res.data&&res.data.resultCode == 0){
                if(res.data.resultData.list.length == 0){
                    _this.$message.warning('没有更多数据了～')
                    return 
                }
                if(_this.pageNum === 1){
                    _this.pingLunList = res.data.resultData.list
                }else{
                    _this.pingLunList = [..._this.pingLunList,...res.data.resultData.list]
                }
                
            }
        })
      },
      savePingLun(){ //保存评论
        const param = {
            userId: '436c0ca7efc145a8ba15bb1e6fecb520',
            businessType: '1',
            content: this.replayValue,
            rootId: this.id
        }
        let _this = this
        axios({
            url: this.$baseUrl + 'official/comment/save',
            method: 'POST',
            data: param
        }).then(res => {
            if(res && res.data && res.data.resultCode == 0){
                _this.$message.success('发布评论成功')
                _this.getCommentPage()
            }
        })
      },
      lookDetail(item){
          this.id = item.id
          this.getDetail()
      },
      praise(){
          if(this.isPraise){
              this.dianzan()
          }else{
            this.cancelDianzan()
          }
      },
      dianzan(){
          const param ={
              id: this.id
          }
          let _this = this
          axios({
              url: _this.$baseUrl + 'official/examHall/praise',
              method: 'POST',
              data: param
          }).then(res => {
              if(res&&res.data&&res.data.resultCode == 0){
                  _this.$message.success('点赞成功～')
                  _this.isPraise = false
                  _this.detailMess.praiseNum++
              }
          })
      },
      cancelDianzan(){
          const param ={
              id: this.id
          }
          let _this = this
          axios({
              url: _this.$baseUrl + 'official/examHall/cancelPraise',
              method: 'POST',
              data: param
          }).then(res => {
              if(res&&res.data&&res.data.resultCode == 0){
                  _this.$message.success('取消点赞成功～')
                  _this.isPraise = true
                  _this.detailMess.praiseNum--
              }
          })
      }
  },
  components: {
    HeadTop
  },
}
</script>

<style scoped lang="less">
    ul,li{
        list-style: none;
        margin: 0;
        padding: 0;
    }
    .home{
        background: #f6f6f6;
        width: 1140px;
        margin: auto;
        .messDetail{
            display: flex;
            line-height:60px;
        }
        .load{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(50%, 50%);
        }
        .content{
            display: flex;
            .contLeft{
                width: 870px;
                // min-height: 400px;
                background: #fff;
                padding: 30px;
                    .book-textreadguide {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    .book-textreadguide-content {
                    border-radius: 4px;
                    background-color: #fff;
                    font-weight: normal;
                    width: 800px;
                    justify-content: center;
                    flex-direction: column;
                    .textreadguide-title {
                        font-size: 18px;
                        text-align: center;
                        font-weight: bold;
                        color: #000000;
                    }
                    .textreadguide-date{
                        width: 100%;
                        margin-top: 10px;
                        text-align: center;
                        height: 26px;
                        font-size: 14px;
                        font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
                        font-weight: 400;
                        color: #7F7F7F;
                        line-height: 26px
                    }
                    .textreadguide-content {
                        // font-family: "STSongti-SC-Black";
                        font-size: 16px;
                        white-space: pre-wrap;
                        line-height:2em;
                        color: #000000;
                    }
                    }
                    .dianzan{
                        width: 100px;
                        height: 32px;
                        line-height: 32px;
                        margin: auto;
                        background: linear-gradient(122deg, #FF8D46 0%, #FA5542 100%);
                        border-radius: 4px;
                        text-align: center;
                        cursor: pointer;
                        .dianZanImg{
                            width: 18px;
                            height: 18px;
                            margin-top: -6px;
                        }
                        .dianZanNum{
                            margin-left: 5px;
                            color: #fff;
                            font-size: 18px;
                        }
                    }
            }
            .contRight{
                margin-left:20px ;
                width: 250px;
                background: #fff;
                padding: 25px 20px;
                .title{
                height: 40px;
                line-height: 40px;
                .good_product{
                    font-size: 16px;
                    color: #DB5647;
                    font-weight: 500;
                    margin-left: 10px;
                }
                }
                .pro_list{
                margin-top: 10px;
                    .listDetail{
                        border-bottom: 1px solid #EEE9DE;
                        color: #000000;
                        line-height: 28px;
                        padding: 5px 0px;
                        cursor: pointer;
                    }
                }
            }
        }
        //评论
        .replay{
            margin-top: 20px;
            width: 870px;
            background: #fff;
            margin-bottom: 30px;
            padding: 30px;
            .answerBgcs{
                height: 240px;
                border-bottom: 1px solid #eee9de;
            }
            .list{
                margin-top: 15px;
                .num{
                    height: 28px;
                    font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
                    line-height: 28px;
                    .text{
                        font-size: 16px;
                        color: #000000;
                        font-weight: 500;
                    }
                    .num_num{
                        color: #7F7F7F;
                        font-size: 14px;
                        margin-left: 10px;
                    }
                }
                .listUl{
                    .listLi{
                        display: flex;
                        border-bottom: 1px solid #eee9de;
                        width: 100%;
                        padding: 10px 0px;
                        cursor: pointer;
                        .imgUser{
                            width: 50px;
                            height: 100px;
                            .userImg{
                                width: 44px;
                                height: 44px;
                                border-radius: 50%;
                            }
                        }
                        .userDetail{
                            margin-left: 5px;
                            width: 100%;
                            margin-left: 10px;
                            .name{
                                height: 28px;
                                line-height: 28px;
                                color: #000;
                                font-size: 16px;
                                font-weight: 500;
                            }
                            .content{
                                margin-top: 8px;
                                line-height: 20px;
                                font-size: 14px;
                                width:100%;
                            }
                            .date{
                                margin-top: 8px;
                                color: #7F7F7F;
                                font-size: 12px;
                            }
                        }
                    }
                }
                .lookMore{
                    margin-top: 10px;
                    width: 100%;
                    text-align: center;
                    height: 30px;
                    line-height: 30px;
                    color: #C59A61;
                    font-size: 18px;
                    cursor: pointer;
                }
            }
        }
    }
    .answerBgcs .ant-input {
        background-color: #f6f6f6;
        padding: 20px;
        box-sizing: border-box;
    }
    .ant-btn-danger{
        background: #DB5647;
    }
</style>